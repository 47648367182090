import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import Button from "./Button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <Props of={Button} mdxType="Props" />
    <h2 {...{
      "id": "button-types"
    }}>{`Button types`}</h2>
    <Playground __position={1} __code={'<Button className=\"btn-primary\">Primary Button</Button>\n<Button className=\"btn-secondary\">Secondary Button</Button>\n<Button className=\"btn-anchor\">Anchor Button</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button className="btn-primary" mdxType="Button">
    Primary Button
  </Button>
  <Button className="btn-secondary" mdxType="Button">
    Secondary Button
  </Button>
  <Button className="btn-anchor" mdxType="Button">
    Anchor Button
  </Button>
    </Playground>
    <p>{`By default the button is btn-primary`}</p>
    <h2 {...{
      "id": "button-states"
    }}>{`Button states`}</h2>
    <p>{`By default a button is active, but it can be set to inactive:`}</p>
    <Playground __position={2} __code={'<Button className=\"btn-primary\">Active Button</Button>\n<Button className=\"btn-primary btn-inactive\">Inactive Button</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button className="btn-primary" mdxType="Button">
    Active Button
  </Button>
  <Button className="btn-primary btn-inactive" mdxType="Button">
    Inactive Button
  </Button>
    </Playground>
    <h1 {...{
      "id": "button-using-links"
    }}>{`Button using links`}</h1>
    <p>{`The button is able to redirect the user to a link given when the button is clicked.
It is also able to open it in a new tab `}<em parentName="p">{`blank`}</em>{` or in the same tab `}<em parentName="p">{`top`}</em></p>
    <Playground __position={3} __code={'<Button link=\"https://www.google.com\" target=\"blank\">\n  Button link\n</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button link="https://www.google.com" target="blank" mdxType="Button">Button link</Button>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      